//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import { getLanguageCode } from '@/i18n';

export default {
  name: 'app-set-place-time-modal',
  // props: ['visible', 'images'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      language: getLanguageCode(),
      time: 0,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({

    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    addTime() { this.time++ },
    removeTime() { this.time != 0 ? this.time-- : 0 },
  },
};
