//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectPlaceImageModal from '@/modules/plan/components/select-place-image-modal.vue';
import SetPlaceTimeModal from '@/modules/plan/components/set-place-time-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/i18n';
// import { Container, Draggable } from "vue-smooth-dnd";
import { PlanModel } from '@/modules/plan/plan-model';
import { FormSchema } from '@/shared/form/form-schema';
const { fields } = PlanModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.description,
  fields.type,
  fields.numberOfDays,
  fields.programs,
]);

export default {
  name: 'app-plan-form-step3',
  props: ['id', 'value', ],
// , 'programList''model' 
  components: {
    [SelectPlaceImageModal.name]: SelectPlaceImageModal,
    [SetPlaceTimeModal.name]: SetPlaceTimeModal,
    // Container, 
    // Draggable, 
  },

  data() {
    return {
      step: 1,
      language: getLanguageCode(),
      rules: formSchema.rules(),
      // model: null,
      modalImageVisible: false,
      visibleImageDialog: false,
      modalTimeVisible: false,
      visibleTimeDialog: false,
      selected_cities: ["3509","23701","23700","3510","3508","3507","3506","3505","3504","3503","3502","3501","3500","3499","3498"],
      selected_places: [],
      selectedPlaceID: null,
      selectedProgramKey: null,
      selectedPlaceIndex: null,
      placeImages: [],
      list1: [],
      list2: [],
      // programList: {},
      drag: false,
      duration: null,
      citiesOptions: [],
      popupShow: false,
      days: [
        i18n('entities.plan.days.dayOne'), 
        i18n('entities.plan.days.dayTwo'), 
        i18n('entities.plan.days.dayThree'), 
        i18n('entities.plan.days.dayFour'), 
        i18n('entities.plan.days.dayFive'), 
        i18n('entities.plan.days.daySix'), 
        i18n('entities.plan.days.daySeven')
      ],
    }
  },
  // watch:{
    // programList(newvalue, oldvalue){
    //   console.log('newvalue => ',newvalue);
    //   console.log('oldvalue => ', oldvalue);
    //   debugger
    // }
  // },
  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading', 
      placesRows: 'place/list/rows',  
      loadingPlaces: 'place/list/loading', 
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          label: item.name[this.language],
        }
      })
    },
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record);
    this.model.type = this.fields.type.options[1].value;
    this.model.name = this.model.name || { en: 'undefined', ar: 'undefined' }
    this.model.description = this.model.description || { en: 'undefined', ar: 'undefined' }
  },
  async mounted() {
    await this.doFetchCities()
  },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'plan/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchPlaces: 'place/list/doFetch',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    // days() {
    //   return [
    //     this.i18n('entities.plan.days.dayOne'), 
    //     this.i18n('entities.plan.days.dayTwo'), 
    //     this.i18n('entities.plan.days.dayThree'), 
    //     this.i18n('entities.plan.days.dayFour'), 
    //     this.i18n('entities.plan.days.dayFive'), 
    //     this.i18n('entities.plan.days.daySix'), 
    //     this.i18n('entities.plan.days.daySeven')
    //   ]
    // },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    onOpenModal(type, key, index, id) {
      console.log('type', type);
      console.log('key', key);
      console.log('index', index);
      console.log('id', id);
      console.log('before value time => ', this.value[key][index]['averageTime']);
      console.log('before program list item => ', this.value[key][index]);
      // debugger
      // this.placeImages = this.value[key][index].placePictures.map(item => { return {id: item.id, src: item.publicUrl} })
      this.selectedPlaceID = id;
      this.selectedPlaceIndex = index;
      this.selectedProgramKey = key;
      switch(type) {
        case 'image':
          this.modalImageVisible = true;
          this.visibleImageDialog = true;
          break;
        case 'time':
          this.modalTimeVisible = true
          this.visibleTimeDialog = true;
          break;
        default:
      }
    },
    onModalClose() {
      this.placeImages = [];
      this.time = 0;
      this.selectedPlaceID = null;
      this.selectedPlaceIndex = null;
      this.selectedProgramKey = null;
      this.visibleImageDialog = false;
      this.visibleTimeDialog = false;
      setTimeout(() => {
        this.modalImageVisible = false;
        this.modalTimeVisible = false
      }, 200);
    },
    
    //#region [ Select Image Dialog ]
    onSelectImage(val) {
      console.log('val = ', val)
      this.value[this.selectedProgramKey][this.selectedPlaceIndex]['selectedImage'] = val.src
    },
    doSaveImage() {
      this.onModalClose();
    },
    //#endregion

    //#region [ Set Time Dialog ]
    doSaveTime(time) {
      console.log('time = ', time)
      this.value[this.selectedProgramKey][this.selectedPlaceIndex]['averageTime'] = time
      console.log('after program list time', this.value[this.selectedProgramKey][this.selectedPlaceIndex]['averageTime']);
      console.log('after program list item',this.value[this.selectedProgramKey][this.selectedPlaceIndex]);
      // debugger
      this.onModalClose();
    },
    //#endregion

    onDurationChanged() {
      switch (this.duration) {
        case 'one':
          this.value = { 1: [] };
          this.model.numberOfDays = 1
          this.$refs.daysNum.resetValidation()
          break;
        case 'more':
          this.model.numberOfDays = undefined
          this.value = {}
          break;
        default:
          break;
      }
    },
    onSelectDays() {
      for (let index = 0; index < this.model.numberOfDays; index++) {
        this.value[index+1] = [];
      }
    },
    onClearDays() {
      this.value = {}
    },
    doClear() {
      this.selected_cities = []
    },
    // onClearSearch() {
    //   this.list2 = []
    //   for (let index = 0; index < this.model.numberOfDays; index++) {
    //     this.value[index+1] = [];
    //   }
    // },

    prev() { this.step-- },
    next() { 
      if (this.step == 2 && this.duration != 'one') {
        for (const property in this.value) {
          if (this.value[property].length) {
            this.list2.push(...this.value[property])
          }
        }
        this.step++ 
        return
      } 
      
      if (this.step == 3) {
        return
      } 
      this.step++ 
    },
    doSubmit(){
      this.$emit('submitForm', {
        stepNo: 3,
        programList: this.value
      })
    }
    // async doSubmit() {
    //   if (this.step != 3) {
    //     if (this.step == 1) await this.doFetchPlaces({ filter: { cityIds: this.selected_cities } }) 
    //     if (this.step == 2 && this.duration == 'one') {
    //       this.value[1] = this.list2
    //       // this.model.programs = this.list2.map(el => {
    //       //   return {
    //       //     placeId: el.id,
    //       //     selectedImage: undefined,
    //       //     averageTime: undefined,
    //       //   }
    //       // })
    //     }
    //     return this.next();
    //   }

    //   // let progs = Object.keys(this.value).map(key => {
    //   //   return {
    //   //     name: key,
    //   //     places: this.value[key].map(item => {
    //   //       return {
    //   //         id: item['id'],
    //   //         selectedImage: item['selectedImage'],
    //   //         averageTime: item['averageTime'],
    //   //       }
    //   //     })
    //   //   }
    //   // }) 
    //   // console.log('Progs => ', progs);
    //   const { id, ...values } = formSchema.cast(this.model);
    //   values.numberOfDays = parseInt(this.model.numberOfDays);
    //   values.programs = Object.keys(this.value).map(key => {
    //     return {
    //       name: key,
    //       places: this.value[key].map(item => {
    //         return {
    //           id: item['id'],
    //           selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
    //           averageTime: item['averageTime'] ? item['averageTime'] : 0,
    //         }
    //       })
    //     }
    //   })
    //   if (this.isEditing) {
    //     return await this.doUpdate({
    //       id,
    //       values,
    //     });
    //   } else {
    //     let record = await this.doCreate(values);
    //     return record;
    //   }
    // },
  },
};
