//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectPlaceImageModal from '@/modules/plan/components/select-place-image-modal.vue';
import SetPlaceTimeModal from '@/modules/plan/components/set-place-time-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/i18n';
// import { Container, Draggable } from "vue-smooth-dnd";
import { PlanModel } from '@/modules/plan/plan-model';
import { FormSchema } from '@/shared/form/form-schema';

import PlanFormStep1 from '@/modules/plan/components/plan-form-step1.vue'
import PlanFormStep2 from '@/modules/plan/components/plan-form-step2.vue'
import PlanFormStep3 from '@/modules/plan/components/plan-form-step3.vue'


const { fields } = PlanModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.description,
  fields.type,
  fields.numberOfDays,
  fields.programs,
]);

export default {
  name: 'app-plan-form',
  props: ['id'],

  components: {
    [SelectPlaceImageModal.name]: SelectPlaceImageModal,
    [SetPlaceTimeModal.name]: SetPlaceTimeModal,
    [PlanFormStep1.name]: PlanFormStep1,
    [PlanFormStep2.name]: PlanFormStep2,
    [PlanFormStep3.name]: PlanFormStep3
    // Container, 
    // Draggable, 
  },

  data() {
    return {
      step: 1,
      language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      modalImageVisible: false,
      visibleImageDialog: false,
      modalTimeVisible: false,
      visibleTimeDialog: false,
      selected_cities: [],
      selected_places: [],
      selectedPlaceID: null,
      selectedProgramKey: null,
      selectedPlaceIndex: null,
      placeImages: [],
      list1: [],
      list2: [],
      programList: {},
      drag: false,
      duration: null,
      numberOfDays: 1,
      citiesOptions: [],
      popupShow: false,
      days: [
        i18n('entities.plan.days.dayOne'), 
        i18n('entities.plan.days.dayTwo'), 
        i18n('entities.plan.days.dayThree'), 
        i18n('entities.plan.days.dayFour'), 
        i18n('entities.plan.days.dayFive'), 
        i18n('entities.plan.days.daySix'), 
        i18n('entities.plan.days.daySeven')
      ],
    }
  },
  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      // citiesRows: 'city/list/rows',  
      citiesRows: 'city/list/citiesByCountry',  
      loadingCities: 'city/list/loading', 
      placesRows: 'place/list/rows',  
      loadingPlaces: 'place/list/loading', 
      currentLanguageCode: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
    }),
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          label: item.name[this.language],
        }
      })
    },
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record);
    this.model.type = this.fields.type.options[0].value;
    this.model.name = this.model.name || { en: undefined, ar: undefined }
    this.model.description = this.model.description || { en: undefined, ar: undefined }
    // this.model.startCityId = 
    this.model.numberOfDays = this.fields.numberOfDays.options[1].value;
  },
  async mounted() {
    await this.doFetchByCountryIds({ 
      ids: ['30'],
      orderBy: 'createdAt'
    })
  },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'plan/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchByCountryIds: 'city/list/doFetchByCountryIds',
      doFetchPlaces: 'place/list/doFetch',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    goToNextPage(value){
      this.$refs.stepper.next()
      // this.model
      console.log('model => ',this.model);
      console.log(value);
      console.log('step no => ', value.stepNo);
      console.log('value payload', value.payload);
      if(value.stepNo == 1){
        this.duration = value.payload
        this.numberOfDays = value.numberOfDays
      }
      if(value.stepNo == 2){
        this.programList = value.payload
      }
      // debugger
    },
    // days() {
    //   return [
    //     this.i18n('entities.plan.days.dayOne'), 
    //     this.i18n('entities.plan.days.dayTwo'), 
    //     this.i18n('entities.plan.days.dayThree'), 
    //     this.i18n('entities.plan.days.dayFour'), 
    //     this.i18n('entities.plan.days.dayFive'), 
    //     this.i18n('entities.plan.days.daySix'), 
    //     this.i18n('entities.plan.days.daySeven')
    //   ]
    // },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    onOpenModal(type, key, index, id) {
      this.placeImages = this.programList[key][index].placePictures.map(item => { return {id: item.id, src: item.publicUrl} })
      this.selectedPlaceID = id;
      this.selectedPlaceIndex = index;
      this.selectedProgramKey = key;
      switch(type) {
        case 'image':
          this.modalImageVisible = true;
          this.visibleImageDialog = true;
          break;
        case 'time':
          this.modalTimeVisible = true
          this.visibleTimeDialog = true;
          break;
        default:
      }
    },
    onModalClose() {
      this.placeImages = [];
      this.time = 0;
      this.selectedPlaceID = null;
      this.selectedPlaceIndex = null;
      this.selectedProgramKey = null;
      this.visibleImageDialog = false;
      this.visibleTimeDialog = false;
      setTimeout(() => {
        this.modalImageVisible = false;
        this.modalTimeVisible = false
      }, 200);
    },
    
    //#region [ Select Image Dialog ]
    onSelectImage(val) {
      console.log('val = ', val)
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['selectedImage'] = val.src
    },
    doSaveImage() {
      this.onModalClose();
    },
    //#endregion

    //#region [ Set Time Dialog ]
    doSaveTime(time) {
      console.log('time = ', time)
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['averageTime'] = time
      this.onModalClose();
    },
    //#endregion

    onDurationChanged() {
      switch (this.duration) {
        case 'one':
          this.programList = { 1: [] };
          this.model.numberOfDays = 1
          this.$refs.daysNum.resetValidation()
          break;
        case 'more':
          this.model.numberOfDays = undefined
          this.programList = {}
          break;
        default:
          break;
      }
    },
    onSelectDays() {
      for (let index = 0; index < this.model.numberOfDays; index++) {
        this.programList[index+1] = [];
      }
    },
    onClearDays() {
      this.programList = {}
    },
    doClear() {
      this.selected_cities = []
    },
    // onClearSearch() {
    //   this.list2 = []
    //   for (let index = 0; index < this.model.numberOfDays; index++) {
    //     this.programList[index+1] = [];
    //   }
    // },

    prev() { this.step-- },
    next() { 
      if (this.step == 2 && this.duration != 'one') {
        for (const property in this.programList) {
          if (this.programList[property].length) {
            this.list2.push(...this.programList[property])
          }
        }
        this.step++ 
        return
      } 
      
      if (this.step == 3) {
        return
      } 
      this.step++ 
    },
    async doSubmit(payload){
      console.log('programList => ',payload.programList);
      this.programList = payload.programList
      console.log('model => ',this.model);
      this.model.programs = Object.keys(this.programList).map(key => {
        return {
          name: key,
          places: this.programList[key].map(item => {
            return {
              id: item['id'],
              selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
              averageTime: item['averageTime'] ? item['averageTime'] : 0,
            }
          })
        }
      })
      console.log('this.model after =>', this.model);
      console.log('this.model.programs after =>', this.model.programs);


      // const { id, ...values } = formSchema.cast(this.model);
      // console.log('id => ', id);
      // console.log('values =>', values);
      // values.numberOfDays = parseInt(this.model.numberOfDays);
      // values.programs = Object.keys(this.programList).map(key => {
      //   return {
      //     name: key,
      //     places: this.programList[key].map(item => {
      //       return {
      //         id: item['id'],
      //         selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
      //         averageTime: item['averageTime'] ? item['averageTime'] : 0,
      //       }
      //     })
      //   }
      // })
      // console.log('values.programs => ', values.programs);
      // debugger
      this.model['ownerId']=this.currentUser.companyId
      await this.doCreate(this.model);
        // return record;
    }
    // async doSubmit() {
    //   // if (this.step != 3) {
    //   //   if (this.step == 1) {
    //   //     console.log('cityIds => ',this.selected_cities);
    //   //     await this.doFetchPlaces({ filter: { cityIds: this.selected_cities } }) 
    //   //   }
    //   //   if (this.step == 2 && this.duration == 'one') {
    //   //     this.programList[1] = this.list2
    //   //     // this.model.programs = this.list2.map(el => {
    //   //     //   return {
    //   //     //     placeId: el.id,
    //   //     //     selectedImage: undefined,
    //   //     //     averageTime: undefined,
    //   //     //   }
    //   //     // })
    //   //   }
    //   //   return this.next();
    //   // }

    //   // let progs = Object.keys(this.programList).map(key => {
    //   //   return {
    //   //     name: key,
    //   //     places: this.programList[key].map(item => {
    //   //       return {
    //   //         id: item['id'],
    //   //         selectedImage: item['selectedImage'],
    //   //         averageTime: item['averageTime'],
    //   //       }
    //   //     })
    //   //   }
    //   // }) 
    //   // console.log('Progs => ', progs);
    //   const { id, ...values } = formSchema.cast(this.model);
    //   console.log('id => ',id);
    //   console.log('values => ', values);
    //   debugger
    //   // values.numberOfDays = parseInt(this.model.numberOfDays);
    //   // values.programs = Object.keys(this.programList).map(key => {
    //   //   return {
    //   //     name: key,
    //   //     places: this.programList[key].map(item => {
    //   //       return {
    //   //         id: item['id'],
    //   //         selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
    //   //         averageTime: item['averageTime'] ? item['averageTime'] : 0,
    //   //       }
    //   //     })
    //   //   }
    //   // })
    //   // if (this.isEditing) {
    //   //   return await this.doUpdate({
    //   //     id,
    //   //     values,
    //   //   });
    //   // } 
    //   // else {
    //   //   let record = await this.doCreate(values);
    //   //   return record;
    //   // }
    // },
  },
};
