//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectPlaceImageModal from '@/modules/plan/components/select-place-image-modal.vue';
import SetPlaceTimeModal from '@/modules/plan/components/set-place-time-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import vueI18n  from '@/vueI18n';
import { i18n } from '@/vueI18n';

// import { Container, Draggable } from "vue-smooth-dnd";

import { PlanModel } from '@/modules/plan/plan-model';
import { FormSchema } from '@/shared/form/form-schema';
const { fields } = PlanModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.description,
  fields.type,
  fields.numberOfDays,
  fields.programs,
]);

export default {
  name: 'app-plan-form-step1',
  props: ['id','value'],

  components: {
    [SelectPlaceImageModal.name]: SelectPlaceImageModal,
    [SetPlaceTimeModal.name]: SetPlaceTimeModal,
    // Container, 
    // Draggable, 
  },

  data() {
    return {
      step: 1,
      rules: formSchema.rules(),
      // model: null,
      modalImageVisible: false,
      visibleImageDialog: false,
      modalTimeVisible: false,
      visibleTimeDialog: false,
      selected_cities: [],
      selected_places: [],
      selectedPlaceID: null,
      selectedProgramKey: null,
      selectedPlaceIndex: null,
      placeImages: [],
      list1: [],
      list2: [],
      programList: {},
      drag: false,
      duration: null,
      citiesOptions: [],
      popupShow: false,
      days: [
        i18n('entities.plan.days.dayOne'), 
        i18n('entities.plan.days.dayTwo'), 
        i18n('entities.plan.days.dayThree'), 
        i18n('entities.plan.days.dayFour'), 
        i18n('entities.plan.days.dayFive'), 
        i18n('entities.plan.days.daySix'), 
        i18n('entities.plan.days.daySeven')
      ],
    }
  },
  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      // citiesRows: 'city/list/rows',  
      citiesRows: 'city/list/citiesByCountry',    
      loadingCities: 'city/list/loading', 
      placesRows: 'place/list/rows',  
      loadingPlaces: 'place/list/loading', 
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    language() {
      return vueI18n.locale
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          label: item.name[this.language],
        }
      })
    },
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    // this.model = formSchema.initialValues(this.record);
    // this.model.type = this.fields.type.options[1].value;
    // this.model.name = this.model.name || { en: 'undefined', ar: 'undefined' }
    // this.model.description = this.model.description || { en: 'undefined', ar: 'undefined' }

    // this.model.startCityId = "23701"
  },
  async mounted() {
    await this.doFetchByCountryIds({ 
      ids: ['30'],
      orderBy: 'createdAt'
    })
  },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'plan/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchByCountryIds: 'city/list/doFetchByCountryIds',
      doFetchPlaces: 'place/list/doFetchPlaceCities',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    // days() {
    //   return [
    //     this.i18n('entities.plan.days.dayOne'), 
    //     this.i18n('entities.plan.days.dayTwo'), 
    //     this.i18n('entities.plan.days.dayThree'), 
    //     this.i18n('entities.plan.days.dayFour'), 
    //     this.i18n('entities.plan.days.dayFive'), 
    //     this.i18n('entities.plan.days.daySix'), 
    //     this.i18n('entities.plan.days.daySeven')
    //   ]
    // },
    doReset() {
      // this.model = formSchema.initialValues(this.record);
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    onOpenModal(type, key, index, id) {
      this.placeImages = this.programList[key][index].placePictures.map(item => { return {id: item.id, src: item.publicUrl} })
      this.selectedPlaceID = id;
      this.selectedPlaceIndex = index;
      this.selectedProgramKey = key;
      switch(type) {
        case 'image':
          this.modalImageVisible = true;
          this.visibleImageDialog = true;
          break;
        case 'time':
          this.modalTimeVisible = true
          this.visibleTimeDialog = true;
          break;
        default:
      }
    },
    onModalClose() {
      this.placeImages = [];
      this.time = 0;
      this.selectedPlaceID = null;
      this.selectedPlaceIndex = null;
      this.selectedProgramKey = null;
      this.visibleImageDialog = false;
      this.visibleTimeDialog = false;
      setTimeout(() => {
        this.modalImageVisible = false;
        this.modalTimeVisible = false
      }, 200);
    },
    
    //#region [ Select Image Dialog ]
    onSelectImage(val) {
      console.log('val = ', val)
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['selectedImage'] = val.src
    },
    doSaveImage() {
      this.onModalClose();
    },
    //#endregion

    //#region [ Set Time Dialog ]
    doSaveTime(time) {
      console.log('time = ', time)
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['averageTime'] = time
      this.onModalClose();
    },
    //#endregion

    onDurationChanged() {
      switch (this.duration) {
        case 'one':
          this.programList = { 1: [] };
          this.value.numberOfDays = 1
          this.$refs.daysNum.resetValidation()
          break;
        case 'more':
          this.value.numberOfDays = undefined
          this.programList = {}
          break;
        default:
          break;
      }
    },
    onSelectDays() {
      for (let index = 0; index < this.value.numberOfDays; index++) {
        this.programList[index+1] = [];
      }
      // console.log(this.programList)
      // console.log(this.value.numberOfDays);
      // debugger
    },
    onClearDays() {
      this.programList = {}
    },
    doClear() {
      this.selected_cities = []
    },
    // onClearSearch() {
    //   this.list2 = []
    //   for (let index = 0; index < this.model.numberOfDays; index++) {
    //     this.programList[index+1] = [];
    //   }
    // },

    prev() { this.step-- },
    next() { 
      if (this.step == 2 && this.duration != 'one') {
        for (const property in this.programList) {
          if (this.programList[property].length) {
            this.list2.push(...this.programList[property])
          }
        }
        this.step++ 
        return
      } 
      
      if (this.step == 3) {
        return
      } 
      this.step++ 
    },
    async doSubmit() {
      console.log('cityIds', this.selected_cities);      
      await this.doFetchPlaces({ filter: { cityIds: this.selected_cities } }) 
      console.log(this.placesRows);   
      debugger   
      this.$emit('goToNextPage', { stepNo: 1 , payload: this.duration,numberOfDays:this.value.numberOfDays })
    },
  },
};
